import Settings from '../domain/Settings';
import {hasPurposesConsent} from '../services/privacyService';
import * as storage from '../utils/storage';
import {getGigyaId} from '../utils/gigya';
import {getCxenseProperties} from '../services/cxenseService';
import * as performance from '../utils/performance';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const STORAGE_KEY = 'advert-fingerprintjs';

type FingerprintJSMetaTags = {
	cxenseId?: string;
	pwId?: string;
	accountId?: string;
};

export async function init(): Promise<void> {
	const settings = Settings.getInstance();

	if (!settings.features?.fingerprintJS)
		return;

	if (!settings.fingerprintJS?.apiKey) {
		console.error('[ADVERT] Tried loading FingerprintJS without API key, skipping.');
		return;
	}

	performance.mark('fingerprintjs - start');

	try {
		if (isCached())
			return;

		if (!hasConsent())
			return;

		try {
			const identifiers = getIdentifiers();

			if (!hasSufficientIdentifier(identifiers)) {
				return;
			}

			const fjs = await FingerprintJS.load({ apiKey: settings.fingerprintJS.apiKey});

			performance.mark('fingerprintjs - loaded');

			await fjs.get({
				tag: identifiers
			});

			addToCache();
		} catch (e) {
			console.error('[ADVERT] Something went wrong processing FingerprintJS', e);
		}
	} finally {
		performance.mark('fingerprintjs - end');
	}
}

function isCached(): boolean {
	const storageValue = storage.get(STORAGE_KEY);

	if (!storageValue) {
		return false;
	}

	return new Date(JSON.parse(storageValue).expiration) > new Date();
}

function addToCache() {
	// No need to check purpose 1 as it's already covered with FJS loading
	storage.put(STORAGE_KEY, JSON.stringify({
		expiration: getExpirationDate()
	}));
}

function hasConsent(): boolean {
	return hasPurposesConsent([1, 2, 3, 4, 7, 9, 10]);
}

function hasSufficientIdentifier(identifiers: FingerprintJSMetaTags): boolean {
	return !!identifiers.cxenseId;
}

function getIdentifiers(): FingerprintJSMetaTags {
	return Object.fromEntries(
		Object.entries({
			pwId: storage.getFromCookie('authId'),
			accountId: getGigyaId(),
			cxenseId: getCxenseProperties()?.id
		}).filter(([, v]) => v)
	);
}

function getExpirationDate(): string {
	const currDate = new Date();

	currDate.setDate(currDate.getDate() + 1);

	return currDate.toISOString();
}
